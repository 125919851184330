import {
	types,
	applySnapshot,
	getRoot,
	getSnapshot,
	isValidReference,
	getType,
} from "mobx-state-tree";
import moment from "moment";
import { Absence, Deployment, ResourceMessage } from "./orders";
import uuid from "uuid";
import omitBy from "lodash.omitby";


export const Position = types
	.model("Position", {
		id: types.identifier,
		lat: types.number,
		lng: types.number,
		time: types.maybeNull(types.Date),
		_resource: types.maybeNull(types.late(() => types.reference(Resource))),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		get resource() {
			if (isValidReference(() => self._resource)) return self._resource;
			return null;
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			self._resource.registerPosition(self.id);
		},
	}));

export const Resource = types
	.model("Resource", {
		id: types.identifier,
		name: types.string,
		image: types.string,
		inventaryNumber: types.optional(types.string, ""),
		appCode: types.optional(types.string, ""),
		deleted: types.optional(types.boolean, false),
		_resclass: types.late(() => types.safeReference(ResClass)),
		information: types.map(types.union(types.string, types.number)),
		$deployments: types.map(types.safeReference(types.late(() => Deployment))),
		$absences: types.map(types.safeReference(types.late(() => Absence))),
		$locations: types.map(types.late(() => types.safeReference(Position))),
		$messages: types.map(
			types.safeReference(types.late(() => ResourceMessage))
		),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		get resclass() {
			if (isValidReference(() => self._resclass)) return self._resclass;
			return null;
		},
		get fullName() {
			if (self.inventaryNumber !== "")
				return self.name + " (" + self.inventaryNumber + ")";
			return self.name;
		},
		get lastLocation() {
			if (!self.$locations.size) return null;
			let last_loc = null;
			for (let location of self.$locations.values()) {
				if (!isValidReference(() => location)) continue;
				if (!last_loc || last_loc.time < location.time) last_loc = location;
			}
			return last_loc;
		},
		get imageUrl() {
			if (!isValidReference(() => self._resclass)) return "";
			if (self.image === "") return self._resclass.image;
			else return self.image;
		},
		get messages() {
			const messages = [];
			for (let message of self.$messages.values()) {
				if (!isValidReference(() => message) || message.deleted) continue;
				messages.push(message);
			}
			messages.sort((a, b) =>
				a.creationTime > b.creationTime
					? -1
					: b.creationTime > a.creationTime
					? 1
					: 0
			);
			return messages;
		},
		getAbsencesByDates(dates) {
			dates.sort();
			let start = dates[0];
			let end = dates[dates.length - 1];
			const out = {};
			for (let absence of self.$absences.values()) {
				if (
					!isValidReference(() => absence) ||
					absence.deleted ||
					absence.date < start ||
					absence.date > end
				)
					continue;
				const key = absence.date;
				if (!dates.includes(key) || !isValidReference(() => absence._type))
					continue;
				if (!(absence._type.id in out)) out[absence._type.id] = [];
				out[absence._type.id].push(absence);
			}
			return out;
		},
		getDeployments(
			{ start, end },
			bucketize = true,
			absencesExtra = false,
			includeUnknown = false
		) {
			const deployments = { d: {} };
			for (let o of self.$deployments.values()) {
				
				if (
					!isValidReference(() => o) ||
					o.deleted ||
					!isValidReference(() => o._job) ||
					!isValidReference(() => o._job._project) ||
					!isValidReference(() => o._job._process) ||
					o._job.deleted ||
					o._job._project.deleted ||
					o._job._process.deleted
				) {
					if (!includeUnknown || o.job.id !== false) continue;
				}
				
				if (o.job.end < start || o.job.start > end) continue;
				
				const key = bucketize
					? moment(o.job.start)
							.startOf("day")
							.format("YYYY-MM-DD")
					: "all";
				if (!(key in deployments.d)) {
					deployments.d[key] = [];
				}
				deployments.d[key].push(
					bucketize
						? o
						: {
								type: "deployment",
								start: o.job.start,
								end: o.job.end,
								ref: o,
						  }
				);
			}
			for (let o of self.$absences.values()) {
				if (!isValidReference(() => o) || o.deleted) continue;
				if (o.end < start || o.start > end) continue;
				const key = bucketize
					? moment(o.start)
							.startOf("day")
							.format("YYYY-MM-DD")
					: "all";
				let topkey = "d";
				if (absencesExtra) {
					topkey = o._type.id;
					if (!(topkey in deployments)) {
						deployments[topkey] = {};
					}
				}
				if (!(key in deployments[topkey])) {
					deployments[topkey][key] = [];
				}
				deployments[topkey][key].push(
					bucketize
						? o
						: {
								type: "absence",
								start: o.start,
								end: o.end,
								ref: o,
						  }
				);
			}
			if (bucketize) return absencesExtra ? deployments : deployments.d;
			if ("d" in deployments && "all" in deployments.d)
				return deployments.d.all;
			return [];
		},
		getCurrentCollisions() {
			const deployments = self.getDeployments(
				{
					start: new Date(),
					end: new Date(8640000000000),
				},
				false,
				false,
				true
			);
			deployments.sort((a, b) =>
				a.start < b.start ? -1 : a.start > b.start ? 1 : 0
			);
			const collisions = [];
			let stack = [];
			for (let d of deployments) {
				let newStack = [];
				for (let s of stack) {
					if (s.end <= d.start)
						//we can assume that no following job will collide with this
						continue;
					//we have a collision of d with s

					//keep the collided thing in the stack, in case it collides with others
					newStack.push(s);

					//collisions are allowed
					//if they are of the same project
					if (
						s.type === "deployment" &&
						s.type === d.type &&
						s.ref.job.project.id === d.ref.job.project.id
					)
						continue;
					//or if they are on each others white list. the white list is kept at the deployment with the lesser id
					if (
						(s.ref.id > d.ref.id &&
							d.ref.collisionWhitelist &&
							d.ref.collisionWhitelist.includes(s.ref.id)) ||
						(s.ref.id < d.ref.id &&
							s.ref.collisionWhitelist &&
							s.ref.collisionWhitelist.includes(d.ref.id))
					)
						continue;
					//else this is a real collision. add to collision collection
					collisions.push([s, d]);
				}
				//add this deployment to the stack
				newStack.push(d);
				stack = newStack;
			}
			return collisions;
		},
		getDeploymentsByProject({ start, end }, includeUnknown=false) {
			const deployments = {};
			const x = Array.from(self.$deployments.values());
			x.sort((a, b) =>
				!isValidReference(() => a) ||
				!isValidReference(() => b) ||
				!isValidReference(() => a._job) ||
				!isValidReference(() => b._job)
					? 0
					: a.job.start > b.job.start
					? 1
					: a.job.start < b.job.start
					? -1
					: 0
			);

			for (let o of x) {
				if (
					!isValidReference(() => o) ||
					o.deleted ||
					!isValidReference(() => o._job) ||
					o._job.deleted ||
					!isValidReference(() => o._job._project) ||
					o._job._project.deleted ||
					!isValidReference(() => o._job._process) ||
					o._job._process.deleted
				) {
					if (!includeUnknown || o.job.id !== false) continue;
				}
				if (o.job.end < start || o.job.start > end) continue;
				const project = o.job.project.id ? o.job.project.id : "UNKNOWN";
				const key = moment(o.job.start)
					.startOf("day")
					.format("YYYY-MM-DD");
				if (!(key in deployments)) {
					deployments[key] = new Map();
				}
				if (!deployments[key].has(project)) {
					deployments[key].set(project, []);
				}
				deployments[key].get(project).push(o);
			}

			for (let absence of self.$absences.values()) {
				if (
					!isValidReference(() => absence) ||
					!isValidReference(() => absence._type) ||
					absence.deleted ||
					absence.start > end ||
					absence.start < start
				)
					continue;
				const key = moment(absence.start)
					.startOf("day")
					.format("YYYY-MM-DD");
				if (!(key in deployments)) {
					deployments[key] = new Map();
				}
				if (!deployments[key].has("absence." + absence._type.id)) {
					deployments[key].set("absence." + absence._type.id, []);
				}
				deployments[key].get("absence." + absence._type.id).push(absence);
			}
			return deployments;
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			self._resclass.registerResource(self.id);
		},
		registerDeployment(id) {
			self.$deployments.set(id, id);
		},
		registerPosition(id) {
			self.$locations.set(id, id);
		},
		registerAbsence(id) {
			self.$absences.set(id, id);
		},
		registerMessage(id) {
			self.$messages.set(id, id);
		},
		addDeployment(deploymentId) {
			self.deployments.push(deploymentId);
		},
		addAbsences(type, dates) {
			for (let absence of self.$absences.values()) {
				if (
					!isValidReference(() => absence) ||
					absence.deleted ||
					!isValidReference(() => absence._type) ||
					absence._type.id !== type
				)
					continue;
				const key = absence.date;
				var index = dates.indexOf(key);
				if (index !== -1) dates.splice(key, 1);
			}
			for (let date of dates) {
				self.addAbsence(type, date);
			}
		},
		addAbsence(type, date) {
			const id = uuid.v4();
			getRoot(self).orders.updateAbsences([
				{
					id: id,
					date: date,
					_type: type,
					_resource: self.id,
				},
			]);
		},
	}));

export const Requirement = types
	.model("Requirement", {
		id: types.identifier,
		name: types.string,
		deleted: types.optional(types.boolean, false),
		image: types.string,
		_resclass: types.late(() => types.safeReference(ResClass)),
		satisfiedBy: types.maybeNull(types.array(types.string)),
		additionalClasses: types.maybeNull(types.array(types.string)),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.volatile((self) => ({
		isCovered: false,
	}))
	.views((self) => ({
		get resclass() {
			if (isValidReference(() => self._resclass)) return self._resclass;
			return null;
		},
		get imageUrl() {
			if (!isValidReference(() => self._resclass)) return "";
			if (self.image === "") return self._resclass.image;
			else return self.image;
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			self._resclass.registerRequirement(self.id);
		},
	}));

export const ResClass = types
	.model("ResClass", {
		id: types.identifier,
		name: types.string,
		image: types.string,
		deleted: types.optional(types.boolean, false),
		human: types.optional(types.boolean, false),
		$resources: types.map(types.reference(Resource)),
		$requirements: types.map(types.reference(Requirement)),
		updatedAt: types.optional(types.Date, () => new Date()),
		bpoId: types.maybeNull(types.number),
	})
	.views((self) => ({}))
	.actions((self) => ({
		registerResource(id) {
			self.$resources.set(id, id);
		},
		registerRequirement(id) {
			self.$requirements.set(id, id);
		},
	}));

export const ResGroup = types
	.model("ResGroup", {
		id: types.identifier,
		name: types.string,
		deleted: types.optional(types.boolean, false),
		_members: types.array(types.late(() => types.safeReference(Resource))),
		updatedAt: types.optional(types.Date, () => new Date()),
		$deployments: types.map(types.safeReference(types.late(() => Deployment))),
	})
	.views((self) => ({
		getDeployments({ start, end }) {
			const deployments = {};
			for (let o of self.$deployments.values()) {
				if (
					!isValidReference(() => o) ||
					o.deleted ||
					!isValidReference(() => o._job) ||
					o._job.deleted
				)
					continue;
				if (o._job.end < start || o._job.start > end) continue;
				const key = moment(o._job.start)
					.startOf("day")
					.format("YYYY-MM-DD");
				if (!(key in deployments)) {
					deployments[key] = [];
				}
				deployments[key].push(o);
			}
			const currentDays = new Set(Object.keys(deployments));
			for (let member of self._members) {
				if (!isValidReference(() => member) || member.deleted) continue;
				const memberDeployments = member.getDeployments({ start, end });
				for (let day in memberDeployments) {
					if (currentDays.has(day)) continue;
					if (!(day in deployments))
						deployments[day] = { greyDay: true, absences: {}, colors: [] };
					for (let deployment of memberDeployments[day]) {
						if (getType(deployment).name !== "Absence") continue;
						deployments[day].absences[deployment.type.id] =
							deployment.type.name;
						deployments[day].colors.push(deployment.type.color);
					}
				}
			}

			return deployments;
		},
	}))
	.actions((self) => ({
		registerDeployment(id) {
			self.$deployments.set(id, id);
		},
		delete() {
			self.deleted = true;
			self.updatedAt = new Date();
			getRoot(self).resources.triggerCollisionRecalculation();
		},
	}));

const ResourcesStore = types
	.model("ResourcesStore", {
		classes: types.map(ResClass),
		groups: types.map(ResGroup),
		resources: types.map(Resource),
		requirements: types.map(Requirement),
	})
	.volatile((self) => ({
		lastCollisionRelevantChange: new Date(),
		lastCollisionUpdate: new Date(),
		collisionData: [],
	}))
	.views((self) => ({
		getRandomResource(random) {
			const resList = [];
			for (let cl of self.classes.values()) {
				for (let res of cl.$resources.values()) {
					if (!isValidReference(() => res)) continue;
					resList.push(res);
				}
			}
			return resList[Math.floor(random * resList.length)];
		},
		getAllRequirements() {
			const out = [];
			for (let resClass of self.classes.values()) {
				if (resClass.deleted) continue;
				for (let [rId, req] of resClass.$requirements) {
					if (!isValidReference(() => req) || req.deleted) continue;
					out.push([rId, req]);
				}
			}
			return out;
		},
		list() {
			const out = [];

			let resL = [];
			for (let group of self.groups.values()) {
				if (group.deleted) continue;
				resL.push(group);
			}
			if (resL.length)
				out.push({
					resClass: { id: "GROUP" },
					res: resL,
					isGroup: true,
				});

			for (let resClass of self.classes.values()) {
				if (resClass.deleted) continue;
				let resL = [];
				for (let res of resClass.$resources.values()) {
					if (!isValidReference(() => res) || res.deleted) continue;
					resL.push(res);
				}
				resL.sort((a, b) => a.name.localeCompare(b.name));
				out.push({ resClass, res: resL, isGroup: false });
			}
			return out;
		},
		reqList() {
			const out = [];
			const alone = [];
			for (let resClass of self.classes.values()) {
				if (resClass.deleted) continue;
				let resL = [];
				for (let res of resClass.$requirements.values()) {
					if (!isValidReference(() => res) || res.deleted) continue;
					resL.push(res);
				}
				if (resL.length > 1) {
					resL.sort((a, b) => a.name.localeCompare(b.name));
					out.push({ resClass, res: resL });
				} else alone.push(...resL);
			}
			if (alone.length) {
				alone.sort((a, b) => a.name.localeCompare(b.name));
				out.push({ resClass: { id: false }, res: alone });
			}
			return out;
		},
		*getResourceLines(classSelectionOW = false) {
			const classSelection = classSelectionOW
				? classSelectionOW
				: getRoot(self).ui.currentClass;
			for (let [classId, classData] of self.classes) {
				if (
					classData.deleted ||
					(!classSelection.includes("ALL") && !classSelection.includes(classId))
				)
					continue;
				for (let resData of classData.$resources.values()) {
					if (!isValidReference(() => resData) || resData.deleted) continue;
					yield resData;
				}
			}
		},
		getGroupLines() {
			const dd = Array.from(self.groups.values()).filter(
				(x) => isValidReference(() => x) && !x.deleted
			);
			return dd;
		},
		getClassDropdownOptions(t, includeAll) {
			const hasGroups =
				includeAll && includeAll !== "selection" && self.groups.size;

			let out = [];
			let preout = [];

			if (includeAll && includeAll === "selection") {
				preout.push({
					id: "ALL",
					name: t("resources.select.all"),
				});
			} else if (hasGroups) {
				preout.push({
					id: "GROUPS",
					name: t("resources.select.groups"),
				});
				preout.push({
					id: "ALL",
					name: t("resources.select.overview"),
				});
			}

			for (let [id, data] of self.classes) {
				if (data.deleted) continue;
				out.push({
					id: id,
					name: data.name,
					bpo: data.bpoId,
					human: data.human,
				});
			}

			out.sort((a, b) => {
				if (a.human && !b.human) return -1;
				if (b.human && !a.human) return 1;

				return a.name.localeCompare(b.name);
			});

			out = preout.concat(out);

			if (includeAll && includeAll !== "selection" && !hasGroups) {
				out.push({
					id: "GROUPS",
					name: t("resources.select.groups"),
				});
				out.push({
					id: "ALL",
					name: t("resources.select.overview"),
				});
			}

			return out;
		},
		get(resclass, resid) {
			if (self.classes.has(resclass)) {
				const resclassx = self.classes.get(resclass);
				if (resclassx.$resources.has(resid)) {
					const x = resclassx.$resources.get(resid);
					if (!isValidReference(() => x)) return null;
					return x;
				}
			}
			return null;
		},
	}))
	.actions((self) => ({
		triggerCollisionRecalculation() {
			self.lastCollisionRelevantChange = new Date();
		},
		updateCollisions(time) {
			let cols = [];
			for (let resClass of self.classes.values()) {
				if (
					resClass.deleted ||
					resClass.id === "bb3c5479-f1e3-4863-8794-06c730eb0bb7"
				)
					continue;
				for (let res of resClass.$resources.values()) {
					if (!isValidReference(() => res) || res.deleted || res.id === "4f93c805-f966-4ffd-ae8d-fd9b578e26ed") continue;
					cols = cols.concat(res.getCurrentCollisions());
				}
			}

			cols.sort((a, b) => {
				return a[0].start < b[0].start ? -1 : b[0].start < a[0].start ? 1 : 0;
			});

			self.collisionData = cols;
			self.lastCollisionUpdate = time;
		},
		setResources(data) {
			applySnapshot(self.classes, data);
		},
		setGroups(data) {
			applySnapshot(self.groups, data);
		},
		groupDispose(jobs, operations) {
			for (let [groupId, operation] of operations) {
				const group = self.groups.get(groupId);
				for (let resource of group._members) {
					if (!isValidReference(() => resource) || resource.deleted) continue;
					for (const job of jobs) {
						job.dispose(resource, operation, groupId);
					}
				}
			}
		},
		handleVerticalMove(deployments, target, date) {
			//delete all deployments
			const targetObject = self.resources.has(target)
				? self.resources.get(target)
				: self.groups.has(target)
				? self.groups.get(target)
				: false;

			if (!targetObject) return false;
			const isGroup = getType(targetObject).name === "ResGroup";

			const done = new Set();
			for (let deployment of deployments) {
				deployment.delete();
				if (getType(deployment).name === "Absence") {
					if (done.has(deployment._type.id) || isGroup) continue;
					targetObject.addAbsences(deployment._type.id, [date]);
					done.add(deployment._type.id);
				} else {
					if (done.has(deployment._job.id)) continue;
					if (isGroup) {
						for (let member of targetObject._members.values()) {
							if (!isValidReference(() => member) || member.deleted) continue;
							deployment._job.dispose(member, "ADD", targetObject.id);
						}
					} else deployment._job.dispose(targetObject, "ADD");
					done.add(deployment._job.id);
				}
			}
		},
		dispose(jobs, operations) {
			for (let [resourceString, operation] of operations) {
				const [resClass, resourceId] = resourceString.split("#");
				try {
					const resource = self.classes
						.get(resClass)
						.$resources.get(resourceId);
					if (!isValidReference(() => resource))
						throw new Error("Invalid reference");
					for (const job of jobs) {
						job.dispose(resource, operation);
					}
				} catch (e) {}
			}
		},
		update(collection, data, registerRefs) {
			for (let d of data) {
				if (collection.has(d.id)) {
					const originalData = collection.get(d.id);
					if (originalData.updatedAt >= new Date(d.updatedAt)) continue;
					d = Object.assign(
						JSON.parse(JSON.stringify(getSnapshot(originalData))),
						d
					);
				}
				try {
					collection.set(d.id, d);
					if (registerRefs) collection.get(d.id).registerReferences();
				} catch (e) {
					console.log(e);
				}
			}
		},
		collectChanges(lastSave) {
			const collections = ["classes", "resources", "requirements", "groups"];
			const out = {};
			for (let collectionName of collections) {
				const collection = self[collectionName];
				for (let data of collection.values()) {
					if (data.updatedAt < lastSave) continue;
					if (!(collectionName in out)) out[collectionName] = [];
					out[collectionName].push(
						omitBy(
							JSON.parse(JSON.stringify(getSnapshot(data))),
							(value, key) => key.startsWith("$")
						)
					);
				}
			}
			return out;
		},
		updateResClasses(data) {
			self.update(self.classes, data, false);
			self.triggerCollisionRecalculation();
		},
		updateResources(data) {
			self.update(self.resources, data, true);
			self.triggerCollisionRecalculation();
		},
		updateRequirements(data) {
			self.update(self.requirements, data, true);
		},
		updateResGroups(data) {
			self.update(self.groups, data, false);
		},
	}));

export default ResourcesStore;
