import React, { createRef } from "react";
import { isValidReference } from "mobx-state-tree";
import {
	Modal,
	Button,
	Menu,
	Icon,
	Input,
	Loader,
	Radio,
	Dropdown,
} from "semantic-ui-react";
import moment from "moment";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { arrayFilter } from "../../../helpers/calendar.js";
import ResMapDetail from "./resMapDetail";
import Nagel from "../../../images/logo-nagel.png";
import uuid from "uuid";
import AddRentalResource from "./addRentalResource.js";

/*

Ressource wird dann als disponiert angezeigt, wenn die Ressource auf allen Jobs disponiert ist
Ressource wird dann als nicht verfügbar angezeigt, wenn sie während eines der Jobs wo anders ist, ausgenommen einer der Jobs
Zielpunkt der Ressourcenkarte ist der erste der gewählten Jobs
Startpunkt der Ressourcenkarte ist vorherige disponierte Job der Ressource

*/

class ResourceChooser extends React.Component {
	constructor(props) {
		super(props);
		this.state = ResourceChooser.initialState;
		this.onScroll = this.onScroll.bind(this);
		this.saveRentalResource = this.saveRentalResource.bind(this);
	}

	static initialState = {
		filter: "",
		selected: [],
		selectedClass: "",
		activeMap: "",
		marker: false,
		rentalResources: {},
		disposed: new Map(),
		firstPosition: null,
		initiallySelected: [],
		resourceInformation: false,
		jobs: [],
		showUnavailable: false,
		nagel: false,
		project: null,
	};

	componentDidUpdate(prevProps) {
		if (!this.props.open && prevProps.open) {
			this.setState(ResourceChooser.initialState);
		} else if (this.props.open && !prevProps.open) {
			setTimeout(
				(x) =>
					this.setState(
						ResourceChooser.load(
							this.props.app.projects.getJobs(this.props.selectedJobs),
							this.props.app.resources.classes,
							this.props.app.resources.groups,
							this.props.requirement
						)
					),
				5
			);
		}
	}

	static jobOverlaps(x, y) {
		return x.start <= y.end && y.start <= x.end;
	}

	static load(jobs, classes, groups, requirement) {
		let firstJob = null;
		const selected = [];
		const jobIds = new Set();
		const intervals = [];
		const jobsOut = [];
		let project = null;
		//get first jobs location
		for (let job of jobs) {
			if (job.deleted) continue;
			if (
				requirement.id &&
				typeof job.openRequests.find(
					(x) => x.requirement.id === requirement.id
				) === "undefined"
			)
				continue;
			if (firstJob === null || firstJob.start > job.start) {
				firstJob = job;
			}
			project = job.project;
			jobIds.add(job.id);
			intervals.push({ start: job.start, end: job.end });
			jobsOut.push(job);
		}
		const position = firstJob !== null ? firstJob.safePosition : null;
		//console.log(requirement);
		const resourceInformation = new Map();
		const groupDispositionCount = {};
		for (let [classid, resclass] of classes) {
			if (resclass.deleted) continue;
			if (
				requirement.id &&
				requirement.resclass.id !== classid &&
				!requirement.additionalClasses.includes(classid)
			)
				continue;
			for (let [resid, res] of resclass.$resources) {
				if (!isValidReference(() => res) || res.deleted) continue;
				if (
					requirement.id &&
					requirement.satisfiedBy !== null &&
					!requirement.satisfiedBy.includes(resid)
				)
					continue;
				//console.log(res.name);
				let disposedCount = 0;
				let nearestJob = null;
				let isAvailable = true;
				let borderColor = null;

				const overlaps = [];
				for (let d of res.$deployments.values()) {
					if (!isValidReference(() => d) || d.deleted || d.job.deleted)
						continue;
					if (jobIds.has(d.job.id)) {
						disposedCount++;
						if (d._resGroup && isValidReference(() => d._resGroup)) {
							if (!(d._resGroup.id in groupDispositionCount))
								groupDispositionCount[d._resGroup.id] = new Set();
							groupDispositionCount[d._resGroup.id].add(d.job.id);
						}
					} else {
						if (
							firstJob &&
							d.job.end <= firstJob.start &&
							(nearestJob === null || nearestJob.end < d.job.end)
						)
							nearestJob = d.job;

						if (!isAvailable) continue;
						for (let interval of intervals) {
							if (ResourceChooser.jobOverlaps(interval, d.job)) {
								//console.log(interval, d.job);
								isAvailable = false;
								overlaps.push(d.job);
								break;
							}
						}
					}
				}
				if (isAvailable) {
					for (let d of res.$absences.values()) {
						if (!isValidReference(() => d) || d.deleted) continue;
						for (let interval of intervals) {
							if (ResourceChooser.jobOverlaps(interval, d)) {
								borderColor = d.type.color;
								isAvailable = false;
								break;
							}
						}
					}
				}
				if (disposedCount >= jobIds.size) {
					selected.push(classid + "#" + resid);
				}
				resourceInformation.set(classid + "#" + resid, {
					borderColor,
					previousPosition:
						nearestJob !== null ? nearestJob.safePosition : null,
					isAvailable,
					overlappingJobs: overlaps,
				});
			}
		}

		for (let rentalResource of project.$rentalResources.values()) {
			if (!isValidReference(() => rentalResource) || rentalResource.deleted)
				continue;
			let disposedCount = 0;
			//console.log(rentalResource.$rentalDeployments);
			for (let rentalDeployment of rentalResource.$rentalDeployments.values()) {
				if (
					!isValidReference(() => rentalDeployment) ||
					rentalDeployment.deleted
				)
					continue;
				//console.log(rentalDeployment);
				if (jobIds.has(rentalDeployment.job.id)) disposedCount++;
			}
			//console.log(disposedCount);
			if (disposedCount >= jobIds.size) {
				selected.push("RENT#" + rentalResource.id);
			}
		}

		for (let group of groups.values()) {
			if (
				group.id in groupDispositionCount &&
				groupDispositionCount[group.id].size >= jobIds.size
			) {
				selected.push("GROUP#" + group.id);
			}
			let isAvailable = true;
			for (let member of group._members.values()) {
				if (
					!isValidReference(() => member) ||
					member.deleted ||
					member.resclass === null ||
					member.resclass.deleted ||
					!resourceInformation.has(member.resclass.id + "#" + member.id)
				)
					continue;
				const rI = resourceInformation.get(
					member.resclass.id + "#" + member.id
				);
				if (rI.isAvailable) continue;
				isAvailable = false;
				break;
			}

			resourceInformation.set("GROUP#" + group.id, {
				previousPosition: null,
				borderColor: null,
				isAvailable: isAvailable,
				overlappingJobs: [],
			});
		}

		//for each resource iterate over the dispositions and check if it is on another job (not in the list) that is at the same time. also search the previous job nearest to the first to get the start position
		//console.log(resourceInformation);
		return {
			isOpen: true,
			position,
			selected,
			project,
			initiallySelected: selected.slice(),
			resourceInformation,
			jobs: jobsOut,
		};
	}

	saveRentalResource(id, name, supplier, resclass) {
		if (id) {
			this.state.project.updateRentalResource(id, name, supplier);
		} else {
			this.props.app.projects.updateRentalResources([
				{
					id: uuid.v4(),
					_project: this.state.project.id,
					_resclass: resclass,
					_supplier: supplier,
					name: name,
					comment: "",
				},
			]);
		}
	}

	onScroll() {
		this.setState((prev) => {
			if (prev.marker !== false)
				return {
					marker: false,
				};
			else return null;
		});
	}

	componentDidMount() {}

	changeFilter(filter) {
		this.setState({ filter });
	}

	selectClass(cls) {
		this.setState((prevState) => {
			return {
				selectedClass: prevState.selectedClass === "" ? cls : "",
				activeMap: "",
			};
		});
	}

	nagel(nagel) {
		this.setState({ nagel });
	}

	setShowUnavailable(showUnavailable) {
		this.setState({ showUnavailable });
	}

	toggle(e, resClass, resource) {
		e.persist();

		if (e.target.classList.contains("ignore")) return false;

		let markpos = 0;
		try {
			markpos =
				e.clientX - e.target.closest(".resc_wrapper").getBoundingClientRect().x;
		} catch (e) {
			console.log(e);
		}
		const key = resClass + "#" + resource;
		this.setState((prevState) => {
			let selected = prevState.selected;
			let activeMap = "";
			let marker = false;
			if (prevState.selected.includes(key)) {
				selected = selected.filter((x) => x !== key);
			} else {
				activeMap = key;
				marker = markpos;
				selected.push(key);
			}
			return {
				selected,
				activeMap,
				marker,
			};
		});
	}

	render() {
		if (!this.props.open) return null;

		if (this.state.nagel !== false) {
			let firstJob = null;
			let lastJob = null;
			for (let job of this.state.jobs) {
				if (job.deleted) continue;
				if (firstJob === null || firstJob.start > job.start) {
					firstJob = job;
				}
				if (lastJob === null || lastJob.end < job.end) {
					lastJob = job;
				}
			}
			return (
				<Modal
					centered={false}
					className="nagelModal"
					open={this.props.open}
					onClose={(e) => this.nagel(false)}
				>
					<Menu>
						<Menu.Item header>
							{this.props.t("resourceChooser.nagel.heading")}
						</Menu.Item>
						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={(e) => this.nagel(false)}>
								<Icon name="arrow left" /> {this.props.t("back")}
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					<Modal.Content>
						<div
							className="nagelLogo"
							style={{
								backgroundImage: `url(${Nagel})`,
							}}
						/>
						<div className="nagelRow">
							<div className="nagelH">Suchbegriff</div>
							<div className="nagelD">{this.state.nagel}</div>
						</div>
						<div className="nagelRow">
							<div className="nagelH">Mietbeginn</div>
							<div className="nagelD">
								{moment(firstJob.start).format("DD.MM.YYYY")}
							</div>
						</div>
						<div className="nagelRow">
							<div className="nagelH">Mietende</div>
							<div className="nagelD">
								{moment(lastJob.end).format("DD.MM.YYYY")}
							</div>
						</div>
						<div className="nagelRow">
							<div className="nagelH">Ort</div>
							<div className="nagelD">
								{this.state.position !== null
									? this.state.position.lat.toFixed(4) +
									  "," +
									  this.state.position.lng.toFixed(4)
									: "Unbekannt"}
							</div>
						</div>
					</Modal.Content>
				</Modal>
			);
		}

		const rentalResources = this.state.project
			? this.state.project.getRentalResourcesByClass()
			: {};

		//console.log(rentalResources);

		const filter = arrayFilter(this.state.filter);
		const compactmode =
			!this.props.app.ui.settings.autoExpandResources &&
			(this.state.filter === "" && this.state.selectedClass === "");
		return (
			<Modal
				centered={false}
				className="resourceChooserModal"
				open={this.props.open}
				onClose={(e) => this.props.close()}
			>
				{this.state.resourceInformation === false ? (
					<Loader size="large" />
				) : (
					<React.Fragment>
						<Menu color="blue" inverted className="chooserTopMenu">
							<Dropdown
								item
								className="resChoosDrop"
								text={this.props.t("resourceChooser.heading")}
							>
								<Dropdown.Menu className="grey">
									{this.props.app.ui.modules.has("REQUEST_PLANNING") &&
									this.props.app.ui.rights.has("PLAN_REQUESTS") ? (
										<Dropdown.Item
											onClick={(_) =>
												this.props.app.ui.setRequirementMode(true)
											}
										>
											{this.props.t("requirementChooser.heading")}
										</Dropdown.Item>
									) : null}
								</Dropdown.Menu>
							</Dropdown>

							<Menu.Item>
								<Input
									className="icon"
									icon="search"
									placeholder={this.props.t("filter")}
									value={this.state.filter}
									onChange={(e, { value }) => this.changeFilter(value)}
								/>
							</Menu.Item>
							<Menu.Item
								name="showUnavailable"
								onClick={(e) =>
									this.setShowUnavailable(!this.state.showUnavailable)
								}
							>
								<Radio
									inverted
									toggle
									className="inverted"
									checked={!this.state.showUnavailable}
									label={this.props.t("resourceChooser.onlyAvailable")}
								/>
							</Menu.Item>
							<Menu.Menu position="right">
								<Menu.Item name="close it" onClick={(e) => this.props.close()}>
									<Icon name="close" /> {this.props.t("cancel")}
								</Menu.Item>
								<Menu.Item
									name="save it"
									onClick={(e) =>
										this.props.save(
											this.state.jobs,
											this.state.selected,
											this.state.initiallySelected,
											this.state.project
										)
									}
								>
									<Icon name="check" /> {this.props.t("ok")}
								</Menu.Item>
							</Menu.Menu>
						</Menu>
						<div className="outerProjectName">
							{this.state.project.fullName}
							{this.props.requirement.id !== false
								? " - " + this.props.requirement.name
								: ""}
						</div>
						<Modal.Content>
							<div className="resourceChooserWrapper">
								{this.props.app.resources.list().map((c) => {
									if (
										this.state.selectedClass !== "" &&
										this.state.selectedClass !== c.resClass.id
									)
										return null;
									const scrollRef = createRef();
									const order = [];
									let skipped = 0;
									let rescomps = c.res
										.map((r) => {
											if (
												!this.state.resourceInformation.has(
													c.resClass.id + "#" + r.id
												) ||
												!filter([
													r.name,
													"" + r.inventaryNumber,
													c.resClass.name ? c.resClass.name : "",
												])
											)
												return null;
											order.push(c.resClass.id + "#" + r.id);
											const isAvailable = this.state.resourceInformation.has(
												c.resClass.id + "#" + r.id
											)
												? this.state.resourceInformation.get(
														c.resClass.id + "#" + r.id
												  ).isAvailable
												: true;

											const borderColor = this.state.resourceInformation.has(
												c.resClass.id + "#" + r.id
											)
												? this.state.resourceInformation.get(
														c.resClass.id + "#" + r.id
												  ).borderColor
												: null;

											if (!isAvailable && !this.state.showUnavailable) {
												skipped++;
												return false;
											}

											return (
												<div
													key={r.id}
													className={
														"resc_res" +
														(this.state.selected.includes(
															c.resClass.id + "#" + r.id
														)
															? " marked"
															: "") +
														(isAvailable ? "" : " unavailable")
													}
													onClick={(e) => this.toggle(e, c.resClass.id, r.id)}
												>
													
													<div
														className={
															"resc_image" +
															(c.resClass.id === "GROUP"
																? " resc_group picon picon-group"
																: "") +
															(borderColor ? " absenceBorder" : "")
														}
														style={
															borderColor ? {
																borderColor: borderColor
															} : null
														}
													>
														<div
															className="resc_image_background"
															style={
															c.resClass.id === "GROUP"
																? null
																: {
																		backgroundImage: "url(" + r.imageUrl + ")",
																  }
															}
														 />
													</div>
													<div className="resc_name">{r.name}</div>
												</div>
											);
										})
										.filter((x) => x);

									if (c.resClass.id in rentalResources) {
										for (let r of rentalResources[c.resClass.id].values()) {
											const contextRef = createRef();
											rescomps.push(
												<div
													key={r.id}
													className={
														"resc_res" +
														(this.state.selected.includes("RENT#" + r.id)
															? " marked"
															: "")
													}
													onClick={(e) => this.toggle(e, "RENT", r.id)}
												>
													<div className="resc_image rental" ref={contextRef}>
														<div className="bigM">
															{this.props
																.t("resourceChooser.rent")
																.substr(0, 1)}
														</div>
														{this.props.app.ui.rights.has("CREATE_RENTALS") ? (
															<AddRentalResource
																key="rent"
																name={r.name}
																rentId={r.id}
																ctxref={contextRef}
																supplier={r.supplier.id}
																trigger={
																	<div className="rentalEdit ignore">
																		<Icon name="pencil" className="ignore" />
																	</div>
																}
																onSave={this.saveRentalResource}
															/>
														) : null}
													</div>
													<div className="resc_name">{r.name}</div>
												</div>
											);
										}
									}

									/*if ((rescomps.length + skipped) > 0) {
										rescomps.push(
											<AddRentalResource key="rent" trigger={<div
												
												className={"resc_res rent"}
											>
												<div
													className="resc_image"
												
												><Icon name="plus" /></div>
												<div className="resc_name">
													{this.props.t(
														"resourceChooser.forRent"
													)}
												</div>
											</div>} />
										);
									}*/

									if (
										false &&
										rescomps.length + skipped > 0 &&
										!c.resClass.human
									) {
										rescomps.push(
											<div
												key="nagel"
												className={"resc_res nagel"}
												onClick={(e) => this.nagel(c.resClass.name)}
											>
												<div
													className="resc_image"
													style={{
														backgroundImage: `url(${Nagel})`,
													}}
												/>
												<div className="resc_name">
													{this.props.t("resourceChooser.rent")}
												</div>
											</div>
										);
									}

									if (!rescomps.length) {
										return null;
									}

									let pos = this.state.activeMap
										? order.indexOf(this.state.activeMap)
										: -1;
									if (!compactmode && pos > -1) {
										const insertPos = Math.floor(pos / 5) * 5 + 5;
										rescomps.splice(
											insertPos,
											0,
											<ResMapDetail
												key="rema"
												marker={this.state.marker}
												resourceKey={this.state.activeMap}
												position={this.state.position}
												information={
													this.state.resourceInformation.has(
														this.state.activeMap
													)
														? this.state.resourceInformation.get(
																this.state.activeMap
														  )
														: null
												}
											/>
										);
									}

									return (
										<div
											key={c.resClass.id}
											className={
												"resc_classwrapper" +
												(compactmode ? " compactmode" : "")
											}
										>
											<div className="resc_classname">
												<Button
													color={
														this.state.selectedClass === c.resClass.id
															? "grey"
															: null
													}
													onClick={() => this.selectClass(c.resClass.id)}
													icon
													labelPosition="left"
												>
													{c.resClass.id === "GROUP"
														? this.props.t("resourceChooser.groups")
														: c.resClass.name}
													<Icon name="filter" />
												</Button>
												{c.resClass.id === "GROUP" ||
												!this.props.app.ui.rights.has(
													"CREATE_RENTALS"
												) ? null : (
													<AddRentalResource
														key="rent"
														resClass={c.resClass.id}
														scrollRef={scrollRef}
														trigger={
															<Button icon labelPosition="left">
																{this.props.t(
																	"resourceChooser.rentModal.button"
																)}
																<Icon name="plus" />
															</Button>
														}
														onSave={this.saveRentalResource}
													/>
												)}
											</div>
											<div
												ref={scrollRef}
												className="resc_wrapper"
												onScroll={this.onScroll}
											>
												{rescomps}
											</div>
											{compactmode && pos > -1 ? (
												<ResMapDetail
													key="rema"
													marker={this.state.marker}
													resourceKey={this.state.activeMap}
													position={this.state.position}
													information={
														this.state.resourceInformation.has(
															this.state.activeMap
														)
															? this.state.resourceInformation.get(
																	this.state.activeMap
															  )
															: null
													}
												/>
											) : null}
										</div>
									);
								})}
							</div>
						</Modal.Content>
					</React.Fragment>
				)}
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(ResourceChooser)));
