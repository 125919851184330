import React from "react";
//import { Modal, Button, Menu, Icon, Input } from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Map, Marker, TileLayer, Polyline } from "react-leaflet";
import { latLngBounds, AwesomeMarkers } from "leaflet";

const positionIsValid = statePos => {
	return (
		statePos &&
		statePos !== null &&
		statePos.lat !== 0 &&
		statePos.lng !== 0 &&
		statePos.zoom !== 0
	);
};

const ResMapDetail = props => {
	const [resclass, resid] = props.resourceKey.split("#");
	const res = props.app.resources.get(resclass, resid);
	if (res === null) return null;

	const markers = [];
	let line = [];
	if (positionIsValid(res.lastLocation)) {
		markers.push({
			latlng: [res.lastLocation.lat, res.lastLocation.lng],
			zoom: res.lastLocation.zoom,
			key: "lastLocation",
			icon: AwesomeMarkers.icon({
				icon: "map marker alternate",
				markerColor: "darkblue"
			})
		});
	}
	if (positionIsValid(props.information.previousPosition)) {
		markers.push({
			latlng: [
				props.information.previousPosition.lat,
				props.information.previousPosition.lng
			],
			zoom: props.information.previousPosition.zoom,
			key: "previousPosition",
			icon: AwesomeMarkers.icon({
				icon: "flag outline",
				markerColor: "darkgreen"
			})
		});
		line.push([
			props.information.previousPosition.lat,
			props.information.previousPosition.lng
		]);
	}
	if (positionIsValid(props.position)) {
		markers.push({
			latlng: [props.position.lat, props.position.lng],
			zoom: props.position.zoom,
			key: "nextPosition",
			icon: AwesomeMarkers.icon({
				icon: "flag checkered",
				markerColor: "red"
			})
		});
		if (line.length > 0) {
			line.push([props.position.lat, props.position.lng]);
		}
	}

	for (let overlappingJob of props.information.overlappingJobs) {
		if (!positionIsValid(overlappingJob.safePosition)) continue;
		markers.push({
			latlng: [overlappingJob.safePosition.lat, overlappingJob.safePosition.lng],
			zoom: overlappingJob.safePosition.zoom,
			key: overlappingJob.id,
			icon: AwesomeMarkers.icon({
				icon: "lightning",
				markerColor: "orange",
				color: "black"
			})
		});
	}
	//console.log(markers);
	const mapSettings = {};
	if (markers.length > 1) {
		const bounds = latLngBounds(markers[0].latlng);
		for (let i = 0; i < markers.length; i++) {
			bounds.extend(markers[i].latlng);
		}
		if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
			mapSettings.center = markers[0].latlng;
			mapSettings.zoom = props.t("stdPos.zoom", 6);
		} else mapSettings.bounds = bounds.pad(0.3);
	} else if (markers.length === 1) {
		mapSettings.center = markers[0].latlng;
		mapSettings.zoom = props.t("stdPos.zoom", 6);
	} else {
		mapSettings.center = [
			parseFloat(props.t("stdPos.lat", "50.8")),
			parseFloat(props.t("stdPos.lng", "10.5"))
		];
		mapSettings.zoom = parseInt(props.t("stdPos.zoom", "6"));
	}

	//console.log(mapSettings, markers);

	const info = Array.from(res.information);
	return (
		<div className="resMapDetail">
			{props.marker !== false ? (
				<div className="resMapMarker" style={{ left: props.marker }} />
			) : null}
			<div className="resMap">
				<Map {...mapSettings}>
					<TileLayer url="https://maps.bpo-asphalt.de/tile.php?z={z}&x={x}&y={y}&key=dboth&type=standard" />
					{markers.map(m => (
						<Marker icon={m.icon} key={m.key} position={m.latlng} />
					))}
					{line.length === 2 ? (
						<Polyline dashArray={6} color="#666" positions={line} />
					) : null}
				</Map>
			</div>
			{info.length ? (
				<div className="details">
					{info.map(([question, answer]) => (
						<div key={question} className="popupLine">
							<div className="popupQuestion">{question}</div>
							<div className="popupAnswer">{answer}</div>
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};

export default inject("app")(withTranslation()(observer(ResMapDetail)));
