import React from "react";
import {
  Modal,
  Menu,
  Icon,
  Tab,
} from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import EditBasedata from "./basedata";
import EditPosition from "./position";
import clonedeep from "lodash.clonedeep";
import uuid from "uuid";
import EditContacts from "./contacts";
import EditAttachments from "./attachments";

class GlobalProjectEdit extends React.Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
  }

  close() {
    this.props.edit.setGlobal(false);
  }

  changePosition(latlng, zoom) {
    this.props.edit.changeProperty("main", {
      position: {
        lat: latlng.lat,
        lng: latlng.lng,
        zoom: zoom,
      },
    });
    this.props.edit.saveHistory();
  }

  addContact() {
    const functionDefault = this.props.t(
      "edit.fields.contactFunction.types.BAULEITER"
    );
    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state.data);

      const xid = uuid.v4();
      data.contacts = {
        ...state.data.contacts,
        [xid]: {
          id: xid,
          function: functionDefault,
          updatedAt: new Date(),
          person: null,
          readOnly: true,
          deleted: false,
        },
      };

      return { forceRemerge: new Date().getTime(), data: data };
    });
    this.props.edit.saveHistory();
  }

  changeContact(key, val, contact) {
    const additionalData = {};
    if (key === "person" && this.props.app.basedata.persons.has(val)) {
      const type = this.props.app.basedata.persons.get(val).type;
      additionalData.function = this.props.t(
        "edit.fields.contactFunction.types." + type
      );
    }

    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state.data);

      if (!(contact in data.contacts)) return null;
      data.contacts[contact] = {
        ...data.contacts[contact],
        ...additionalData,
        updatedAt: new Date(),
        [key]: val,
      };

      return { forceRemerge: new Date().getTime(), data: data };
    });
  }

  addAttachment(description, aadata) {
    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state.data);

      const xid = uuid.v4();

      const x = {
          id: xid,
          creationTime: new Date(),
          creator: aadata.creator,
          user: aadata.user,
          description: description,
          attachment: aadata.attachment,
          deleted: false,
          updatedAt: new Date(),
        };

     

      data.projectAttachments = {
        ...state.data.projectAttachments,
        [xid]: x,
      };

      return { forceRemerge: new Date().getTime(), data: data };
    });
    this.props.edit.saveHistory();
  }

  deleteAttachment(id) {
    this.props.edit.changeJobProperty(function(state) {
      let data = clonedeep(state.data);

      if (!(id in data.projectAttachments)) {console.log("NOT FOUND ",id); return null;}
      data.projectAttachments[id] = {
        ...data.projectAttachments[id],
        updatedAt: new Date(),
        deleted: true,
      };
      const x = { forceRemerge: new Date().getTime(), data: data };
      console.log(x);
      return x;
    });
  }

  render() {
    return (
      <Modal
        centered={false}
        className="projectEditModal"
        open={this.props.edit.state.global}
        onClose={this.close}
      >
        <Menu>
          <Menu.Item header>{this.props.t("edit.global.heading")}</Menu.Item>
          <Menu.Menu position="right">
            {this.props.edit.state.newProject ? (
              <Menu.Item
                name="close it"
                onClick={(_) => this.props.edit.back()}
              >
                <Icon name="cancel" /> {this.props.t("cancel")}
              </Menu.Item>
            ) : null}
            <Menu.Item name="close it" onClick={this.close}>
              <Icon name="check" /> {this.props.t("ok")}
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Modal.Content>
          <Tab
            className="editTabs"
            activeIndex={this.props.edit.activeGlobalIndex}
            onTabChange={this.props.edit.handleGlobalTabChange}
            menu={{ secondary: true }}
            panes={[
              {
                menuItem: {
                  key: "basedata",
                  icon: "book",
                  content: this.props.t("edit.panes.globalbasedata"),
                },
                render: () => (
                  <EditBasedata
                    global={true}
                    metacom={this.props.app.ui.modules.has("SITE_SELECTOR")}
                    edit={this.props.edit}
                    data={this}
                  />
                ),
              },
              {
                menuItem: {
                  key: "contacts",
                  icon: "users",
                  content: this.props.t("edit.panes.contacts"),
                },
                render: () => (
                  <EditContacts edit={this.props.edit} data={this} />
                ),
              },
              {
                menuItem: {
                  key: "position",
                  icon: "map marker alternate",
                  content: this.props.t("edit.panes.position"),
                },
                render: () => (
                  <EditPosition
                    global={true}
                    edit={this.props.edit}
                    data={this}
                  />
                ),
              },
              {
                menuItem: {
                  key: "attachments",
                  icon: "attach",
                  content: this.props.t("edit.panes.attachments"),
                },
                render: () => (
                  <EditAttachments edit={this.props.edit} data={this} />
                ),
              },
            ]}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation()(inject("app")(observer(GlobalProjectEdit)));
