import React from "react";
import {
	Modal,
	Button,
	Menu,
	Icon,
	Header,
	Loader,
	Dimmer,
	Table,
} from "semantic-ui-react";
import { isValidReference } from "mobx-state-tree";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import QuestionTable from "./QuestionTable";
import QuestionImage from "./QuestionImage";
import Answer from "./Answer";
import axios from "axios";
import ReactToPrint from "react-to-print";

class ReportModal extends React.Component {
	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
		this.open = this.open.bind(this);

		this.state = {
			open: false,
			data: [],
			images: [],
			loading: true,
			error: false,
			resources: [],
			orders: [],
			deliveries: []
		};
	}

	close() {
		this.setState({ open: false });
	}

	open() {
		this.setState({ open: true });
	}

	getUrlParams() {
		const username = localStorage.getItem("binfra_username");
		if (!username || username.length === 0) return "";
		let proof = 0;
		const strx = "Kiara";
		for (let i = 0; i < username.length; i++) {
			const chr = username.charCodeAt(i);
			const f = strx.charCodeAt(i % strx.length);
			proof += chr * f;
			proof %= Math.pow(36, 4);
		}
		return (
			"u=" +
			encodeURIComponent(username) +
			"&p=" +
			encodeURIComponent(Math.abs(proof).toString(36))
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.state.data.length && this.state.open && !prevState.open) {
			this.getData();
		}
	}

	async getData() {
		const jwttoken = localStorage.getItem("binfra_token");
		this.setState({ error: false, loading: true });
		try {
			const res = await axios.get(
				process.env.REACT_APP_SERVER_URL +
					"/loadReportAnswers/" +
					this.props.data.project +
					"/" +
					this.props.data.date +
					"?v=" +
					process.env.REACT_APP_SERVER_VERSION +
					"&" +
					Math.random(),
				{
					headers: {
						Authorization: "Bearer " + jwttoken,
					},
				}
			);
			const resources = this.getResources(this.props.project, this.props.date);
			const orders = this.getOrders(this.props.project, this.props.date);
			const deliveries = await this.getDeliveries(
				this.props.project,
				"bpokey" in this.props.data ? this.props.data.bpokey : "",
				this.props.date
			);
			this.setState({
				error: false,
				loading: false,
				data: this.process(res.data.answers),
				images: res.data.images,
				resources,
				orders,
				deliveries,
			});
		} catch (error) {
			if (error.response && error.response.status === 401) {
				this.props.app.ui.setLoginNecessary(
					"callback",
					this.getData.bind(this)
				);
			}
			this.setState({ error: true, loading: false });
			console.log(error);
		}
	}

	getOrders(projectId, date) {
		if (!this.props.app.projects.projects.has(projectId)) return [];
		const out = [];
		const project = this.props.app.projects.projects.get(projectId);
		for (let process of project.$processes.values()) {
			if (!isValidReference(() => process) || process.deleted) continue;
			for (let job of process.$jobs.values()) {
				if (!isValidReference(() => job) || job.deleted) continue;
				let start = moment(job.start)
					.startOf("day")
					.format("YYYY-MM-DD");
				if (start !== date) continue;
				for (let d of job.$orders.values()) {
					if (!isValidReference(() => d) || d.deleted) continue;
					if (!isValidReference(() => d._material) || d._material.deleted)
						continue;
					out.push({
						material: d._material.fullname,
						supplier: d.supplier.name,
						amount: d.amount + " " + d._material.unit,
						meta: [
							d.area > 0 ? parseFloat(d.area) + " m²" : "",
							d.thickness > 0 ? parseFloat(d.thickness) + " cm" : "",
						].join(", "),
					});
				}
			}
		}
		return out;
	}

	async getDeliveries(projectId, bpoId, date) {
		const res = await axios.get(
			process.env.REACT_APP_BPO_URL +
				"/common/interfaces/InfraTonnages.php?p=" +
				projectId +
				"&b=" +
				bpoId +
				"&d=" +
				date,
			{
				headers: {
					BPOAuth: "6e469243-cf85-466b-92e9-e816f1b3376d",
				},
			}
		);
		return res.data;
	}

	getResources(projectId, date) {
		if (!this.props.app.projects.projects.has(projectId)) return [];
		const out = {};
		const project = this.props.app.projects.projects.get(projectId);
		for (let process of project.$processes.values()) {
			if (!isValidReference(() => process) || process.deleted) continue;
			for (let job of process.$jobs.values()) {
				if (!isValidReference(() => job) || job.deleted) continue;
				let start = moment(job.start)
					.startOf("day")
					.format("YYYY-MM-DD");
				if (start !== date) continue;
				for (let d of job.$deployments.values()) {
					if (!isValidReference(() => d) || d.deleted) continue;
					if (!isValidReference(() => d._resource) || d._resource.deleted)
						continue;
					if (!(d._resource.id in out)) {
						out[d._resource.id] = {
							resource: d._resource,
							status: d.status,
						};
					}
					switch (d.status) {
						case "PRESENT":
							out[d._resource.id].status = d.status;
							break;
						case "MISSING":
							out[d._resource.id].status = d.status;
							break;
						default:
							break;
					}
				}
			}
		}
		const aout = Object.values(out);
		aout.sort((a, b) => {
			const aHuman = a.resource._resclass.human;
			const bHuman = b.resource._resclass.human;
			const aClassName = a.resource._resclass.name;
			const bClassName = b.resource._resclass.name;
			const aName = a.resource.name;
			const bName = b.resource.name;

			return aHuman && !bHuman
				? -1
				: !aHuman && bHuman
				? 1
				: aClassName < bClassName
				? -1
				: aClassName > bClassName
				? 1
				: aName < bName
				? -1
				: aName > bName
				? 1
				: 0;
		});

		const bout = [];
		let cout = [];
		let last = false;
		for (let x of aout) {
			if (last !== false && x.resource._resclass.id !== last) {
				bout.push(cout);
				cout = [];
			}
			last = x.resource._resclass.id;
			cout.push(x);
		}
		if (cout.length) bout.push(cout);

		return bout;
	}

	process(data) {
		//for each questionary join answers of the same resource category
		//in the end sort by questionary, then by category (null first, then human, then name), then by question
		let out = {};
		//console.log("PROCESSS", data);
		for (let x of data) {
			if (
				!this.props.app.questions.questions.has(x._question) &&
				(!x._resource || this.props.app.resources.resources.has(x._resource)) &&
				(!x._answeredBy || this.props.app.resources.resources.has(x.answeredBy))
			) {
				console.log("Unknown question");
				continue;
			}
			const question = this.props.app.questions.questions.get(x._question);
			x._answeredBy = this.props.app.resources.resources.get(x._answeredBy);
			x._question = question;
			if (!question.questionary) {
				console.log("Unknown questionary");
				continue;
			}
			const questionary = question.questionary;
			if (!(questionary.id in out)) {
				out[questionary.id] = {
					questionary,
					categories: {},
				};
			}
			let category = {};
			let classId = "general";
			if (x._resource) {
				const resource = this.props.app.resources.resources.get(x._resource);
				x._resource = resource;
				const rclass = resource.resclass;
				if (!rclass) {
					console.log("Unknown resclass");
					continue;
				}
				category = {
					general: false,
					name: rclass.name,
					human: rclass.human,
				};
				classId = rclass.name + (rclass.human ? "-human" : ""); //rclass.id;
			} else {
				category = {
					general: true,
					name: this.props.t("questions.report.generalQuestions"),
					human: false,
				};
			}
			if (!(classId in out[questionary.id].categories)) {
				out[questionary.id].categories[classId] = {
					category,
					answers: [],
				};
			}
			out[questionary.id].categories[classId].answers.push(x);
		}

		return Object.values(out).sort((a, b) =>
			a.questionary.ordering < b.questionary.ordering
				? -1
				: a.questionary.ordering > b.questionary.ordering
				? 1
				: 0
		);
	}

	render() {
		const title =
			this.props.data.dateFormatted + " – " + this.props.data.projectName;

		//console.log(this.state.data, this.state.resources);
		return (
			<React.Fragment>
				<Modal
					centered={false}
					className="reportModal xModal"
					trigger={<Button icon="arrow right" onClick={this.open} />}
					open={this.state.open}
					onClose={this.close}
				>
					<Menu>
						<Menu.Item header>
							{this.props.t("questions.reports.heading")}
						</Menu.Item>

						<ReactToPrint
							documentTitle={title}
							trigger={() => {
								return (
									<Menu.Item name="print">
										<Icon name="print" />{" "}
										{this.props.t("questions.reports.print")}
									</Menu.Item>
								);
							}}
							content={() => this.componentRef}
						/>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={this.close}>
								<Icon name="close" /> {this.props.t("close")}
							</Menu.Item>
						</Menu.Menu>
					</Menu>

					<Modal.Content>
						{this.state.open ? (
							<div className="agfWrapper">
								<div ref={(el) => (this.componentRef = el)}>
									{this.state.error ? (
										<Header
											style={{
												margin: 20,
												textAlign: "center",
												display: "block",
											}}
											className="connectionError"
											icon
										>
											<Icon name="wifi" />
											{this.props.t("loader.networkError")}
											<Header.Subheader>
												<Button onClick={(x) => this.getData()}>
													{this.props.t("loader.tryAgain")}
												</Button>
											</Header.Subheader>
										</Header>
									) : this.state.loading ? (
										<Dimmer active inverted>
											<Loader
												style={{ margin: 20 }}
												active
												inverted
												inline="centered"
												size="large"
											/>
										</Dimmer>
									) : (
										<React.Fragment>
											<div class="titleWrap">
												<div class="titleCol">
													<div className="qQuestionarySubTitle">
														{this.props.t("questions.reports.heading")}
													</div>
													<Header
														size="large"
														className="qQuestionaryMainTitle"
													>
														{title}
													</Header>
													<div className="qQuestionaryStandWrapper">
														<span className="qQuestionaryStand">
															{this.props.t("questions.reports.stand") + ":"}
														</span>
														{moment().format("Do MMMM YYYY HH:mm:ss")}
													</div>
												</div>
												<div class="imgCol">
													<img
														alt="Company logo"
														class="logoimg"
														src={
															"" +
															process.env.REACT_APP_BPO_URL +
															"/common/interfaces/InfraLogo.php?" +
															this.getUrlParams()
														}
													/>
												</div>
											</div>
											{this.state.data.map((x) => (
												<div className="qQuestionaryWrapper">
													<Header size="medium" className="qQuestionaryTitle">
														{x.questionary.name}
													</Header>
													<div className="qCategoryContainer">
														{Object.values(x.categories)
															.sort((a, b) =>
																a.category.general && !b.category.general
																	? -1
																	: b.category.general && !a.category.general
																	? 1
																	: a.category.human && !b.category.human
																	? -1
																	: b.category.human && !a.category.human
																	? 1
																	: a.category.name.localeCompare(
																			b.category.name
																	  )
															)
															.map((y, i, a) => (
																<div className="qCategoryWrapper">
																	{y.category.general ? (
																		<Table celled definition>
																			<Table.Body>
																				{y.answers
																					.sort((a, b) =>
																						a._question.ordering <
																						b._question.ordering
																							? -1
																							: a._question.ordering >
																							  b._question.ordering
																							? 1
																							: 0
																					)
																					.map((z) => (
																						<Table.Row className="qQuestionWrapper">
																							<Table.Cell
																								collapsing
																								className="qQuestionTitle"
																							>
																								{z._question.title}
																							</Table.Cell>
																							<Table.Cell className="qAnswer">
																								<Answer answer={z} />
																							</Table.Cell>
																						</Table.Row>
																					))}
																			</Table.Body>
																		</Table>
																	) : (
																		<QuestionTable
																			title={y.category.name}
																			answers={y.answers}
																		/>
																	)}
																</div>
															))}
													</div>
												</div>
											))}
											{this.state.orders.length ? (
												<div className="qQuestionaryWrapper">
													<Header size="medium" className="qQuestionaryTitle">
														{this.props.t("questions.reports.orders")}
													</Header>
													<div className="qCategoryContainer">
														<div className="qCategoryWrapper">
															<Table celled>
																<Table.Header>
																	<Table.Row>
																		<Table.HeaderCell>
																			{this.props.t(
																				"edit.fields.orderMaterial.label"
																			)}
																		</Table.HeaderCell>
																		<Table.HeaderCell>
																			{this.props.t(
																				"edit.fields.orderDeliverer.label"
																			)}
																		</Table.HeaderCell>
																		<Table.HeaderCell>
																			{this.props.t(
																				"edit.fields.orderAmount.label"
																			)}
																		</Table.HeaderCell>
																	</Table.Row>
																</Table.Header>
																<Table.Body>
																	{this.state.orders.map((y) => (
																		<React.Fragment>
																			<Table.Row>
																				<Table.Cell>{y.material}</Table.Cell>
																				<Table.Cell>{y.supplier}</Table.Cell>
																				<Table.Cell>{y.amount}</Table.Cell>
																			</Table.Row>
																			{y.meta.length > 0 ? (
																				<Table.Row>
																					<Table.Cell
																						textAlign="right"
																						style={{
																							fontSize: "90%",
																						}}
																						colspan={3}
																					>
																						{y.meta}
																					</Table.Cell>
																				</Table.Row>
																			) : null}
																		</React.Fragment>
																	))}
																</Table.Body>
															</Table>
														</div>
													</div>
												</div>
											) : null}
											{this.state.deliveries.length ? (
												<div className="qQuestionaryWrapper">
													<Header size="medium" className="qQuestionaryTitle">
														{this.props.t("questions.reports.deliveries")}
													</Header>
													<div className="qCategoryContainer">
														<div className="qCategoryWrapper">
															<Table celled>
																<Table.Header>
																	<Table.Row>
																		<Table.HeaderCell>
																			{this.props.t(
																				"edit.fields.orderMaterial.label"
																			)}
																		</Table.HeaderCell>
																		<Table.HeaderCell>
																			{this.props.t(
																				"edit.fields.orderDeliverer.label"
																			)}
																		</Table.HeaderCell>
																		<Table.HeaderCell>
																			{this.props.t(
																				"edit.fields.orderAmount.label"
																			)}
																		</Table.HeaderCell>
																	</Table.Row>
																</Table.Header>
																<Table.Body>
																	{this.state.deliveries.map((y) => (
																		<React.Fragment>
																			<Table.Row>
																				<Table.Cell>{y.material}</Table.Cell>
																				<Table.Cell>{y.supplier}</Table.Cell>
																				<Table.Cell>{y.amount}</Table.Cell>
																			</Table.Row>
																			{y.meta.length > 0 ? (
																				<Table.Row>
																					<Table.Cell
																						textAlign="right"
																						style={{
																							fontSize: "90%",
																						}}
																						colspan={3}
																					>
																						{y.meta}
																					</Table.Cell>
																				</Table.Row>
																			) : null}
																		</React.Fragment>
																	))}
																</Table.Body>
															</Table>
														</div>
													</div>
												</div>
											) : null}
											{this.state.resources.length ? (
												<div className="qQuestionaryWrapper">
													<Header size="medium" className="qQuestionaryTitle">
														{this.props.t("lineheaders.resources")}
													</Header>
													<div className="qCategoryContainer">
														<div className="qCategoryWrapper">
															<Table celled definition>
																<Table.Body>
																	{this.state.resources.map((y) => (
																		<React.Fragment>
																			<Table.Row className="qQuestionWrapper">
																				<Table.Cell collapsing colspan={2}>
																					{y[0].resource._resclass.name}
																				</Table.Cell>
																			</Table.Row>
																			{y.map((z) => (
																				<Table.Row className="qQuestionWrapper">
																					<Table.Cell
																						collapsing
																						className="qColName"
																					>
																						{z.resource.fullName}
																					</Table.Cell>
																					<Table.Cell className="qAnswer">
																						{z.status === "MISSING" ? (
																							<Icon
																								circular
																								className="missingIcon"
																								inverted
																								size="small"
																								name="x"
																							/>
																						) : z.status === "PRESENT" ? (
																							<Icon
																								circular
																								className="presentIcon"
																								inverted
																								size="small"
																								name="check"
																							/>
																						) : (
																							<Icon
																								circular
																								className="unknownIcon"
																								inverted
																								size="small"
																								name="question circle"
																							/>
																						)}
																					</Table.Cell>
																				</Table.Row>
																			))}
																		</React.Fragment>
																	))}
																</Table.Body>
															</Table>
														</div>
													</div>
												</div>
											) : null}
											{this.state.images.length ? (
												<div
													id="qQuestionariesImages"
													className="qQuestionaryWrapper"
												>
													<Header size="medium" className="qQuestionaryTitle">
														{this.props.t("questions.reports.images")}
													</Header>
													<div className="qImageContainer">
														{Object.values(this.state.images)
															.sort((a, b) =>
																a.creationTime < b.creationTime
																	? -1
																	: a.creationTime > b.creationTime
																	? 1
																	: 0
															)
															.map((y, i, a) => (
																<QuestionImage data={y} />
															))}
													</div>
												</div>
											) : null}
										</React.Fragment>
									)}
								</div>
							</div>
						) : null}
					</Modal.Content>
				</Modal>
			</React.Fragment>
		);
	}
}

export default withTranslation()(inject("app")(observer(ReportModal)));
