import React from "react";
import { observer, inject } from "mobx-react";
import { strToDate } from "../../../helpers/calendar";
import moment from "moment";
import { Popup } from "semantic-ui-react";

class MixingPlantLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static getX(start, calDuration, xwidth, date) {
		//console.log(date, start);
		return ((date.getTime() - start.getTime()) / calDuration) * xwidth;
	}

	render() {
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;
		const lineHeight = 20;
		//console.log("MPLINE RENDERS");
		const padding = 0;
		const boxHeight = lineHeight - 2 * padding;
		const days = {};

		for (let order of this.props.data.orders) {
			if (order._supplier.id === "de7798c9-b582-4991-bb9a-9e63c19b08")
				console.log("ORDER", order);
			const start = moment(order._job.start);
			const key = start.format("YYYY-MM-DD");
			if (!(key in days))
				days[key] = {
					sum: 0,
					date: key,
					info: {},
				};
			if (!(order._project.id in days[key].info)) {
				days[key].info[order._project.id] = {
					name: order._project.name,
					bstn: order._project.bstn,
					orders: [],
				};
			}

			days[key].info[order._project.id].orders.push({
				start: start.format("HH:mm"),
				end: moment(order._job.end).format("HH:mm"),
				amount: order.amount,
				material: order.material.fullname ? order.material.fullname : "?",
				unit: order.material.unit ? order.material.unit : "t",
			});
			days[key].sum += order.amount;
		}

		const boxes = Object.values(days).map((x) => {
			//console.log("MPLINE", this.props.data);

			let start = MixingPlantLine.getX(
				this.props.app.ui.view.start,
				this.props.app.ui.calDuration,
				this.props.xwidth,
				strToDate(x.date)
			);

			const end = MixingPlantLine.getX(
				this.props.app.ui.view.start,
				this.props.app.ui.calDuration,
				this.props.xwidth,
				strToDate(x.date, 1)
			);

			const stg = 95 - Math.max(0, Math.min(x.sum / 2500, 1)) * 65;

			const infoContent = [];

			const info = Object.values(x.info);
			info.sort((a, b) => a.name.localeCompare(b.name));
			for (let cs of info) {
				infoContent.push(
					<div className="mpinfo_csname">
						{cs.bstn + (cs.bstn.length ? ": " : "") + cs.name}
					</div>
				);
				cs.orders.sort((a, b) => {
					const start = a.start.localeCompare(b.start);
					if (start !== 0) return start;
					return a.material.localeCompare(b.material);
				});
				let lastStart = "";
				for (let order of cs.orders) {
					const startString = order.start + "–" + order.end;
					infoContent.push(
						<div className="mpinfo_line">
							<div className="mpinfo_start">
								{startString === lastStart ? "" : startString}
							</div>
							<div className="mpinfo_amount">
								{order.amount + order.unit + " " + order.material}
							</div>
						</div>
					);
					lastStart = startString;
				}
			}

			return (
				<Popup
					key={x.date}
					wide
					on="click"
					popperModifiers={[
						{
							name: "zIndex",
							enabled: true,
							phase: "write",
							fn: ({ state }) => {
								state.styles.popper.zIndex = 42;
							},
						},
					]}
					position="bottom left"
					trigger={
						<div
							className="process_box mp_box"
							style={{
								cursor: "pointer",
								backgroundColor: "hsl(211, 70%, " + stg + "%)",
								color: stg > 55 ? "black" : "white",
								position: "absolute",
								left: start + 1,
								width: end - start - 1,
								height: boxHeight - 1,
								top: padding,
								lineHeight: boxHeight - 1 + "px",
							}}
							onClick={null}
						>
							{x.sum}
						</div>
					}
				>
					{infoContent}
				</Popup>
			);
		});

		return (
			<tr className="mp_line project_line project_process">
				<td
					className="mp_head project_head sticky"
					style={{
						cursor: "pointer",
						left: 0,
						zIndex: 3,
						verticalAlign: "top",
					}}
				>
					<div
						className={"mp_name project_name"+(this.props.app.ui.modules.has("LARGER_PROJECTNAMES") ? " extended" : "")}
						style={{
							whiteSpace: "nowrap",
							height: lineHeight,
						}}
					>
						<div className="nameInner">{this.props.data.name}</div>
					</div>
				</td>
				<td>
					<div
						className="mp_body project_body"
						style={{
							zIndex: 2,
							width: width,
							height: lineHeight,
							position: "relative",
						}}
					>
						{boxes}
					</div>
				</td>
			</tr>
		);
	}
}

export default inject("app")(observer(MixingPlantLine));
