import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

const ClassDropdown = props => {

	console.log("Class Dropdown got props",props);

	const { t } = useTranslation();
	let xtext = t("resources.select.choose");
	const options = props.options.map(x => {
		if (props.value === x.id) xtext = x.name;
		return {
			key: x.id,
			value: x.id,
			text: x.name,
			content: <div className="selectorContent"><div className="selName">{x.name}</div>{x.id !== "ALL" ? <Icon className="addbutton" name="plus" /> : null}</div>
		};
	});

	return (
		<Dropdown
			value={props.value}
			options={options}
			onChange={props.onChange}

			multiple
			//selection
			//fluid

			button
			className={"xlabeled icon"+(props.value.length === 1 ? " onlyone" : "")}
			floating
			//labeled
			icon="filter"
			//search
			text={xtext}
			noResultsMessage={t("resources.select.nothingFound")}
		/>
	);
};

export default ClassDropdown;
