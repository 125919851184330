import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
	Tab,
	Table,
	Button,
	Segment,
	Icon,
	Header,
	Message,
} from "semantic-ui-react";
import BlurChangeInput from "../controls/blurChangeInput";
import ConfirmButton from "../controls/confirmButton";
import BaseDataSelect from "../../basedata/selects";

const EditDispo = (props) => {
	const { t } = useTranslation();
	const state = props.data.state.formData.disposition;
	const truckTonnage = props.data.state.formData.truckTonnage;

	if (!state.mergeable) {
		return (
			<Tab.Pane>
				<Segment placeholder>
					<Header icon>
						<Icon name="truck" />
						{t("edit.difference.header", {
							type: t("edit.difference.types.dispo"),
						})}
						<Header.Subheader>
							{t("edit.difference.message", {
								type: t("edit.difference.types.dispo"),
							})}
						</Header.Subheader>
					</Header>
					{!props.edit.state.data.editable ||
					props.data.state.formData.bpo.length ? null : (
						<ConfirmButton
							trigger={
								<Button negative>
									{t("edit.difference.action", {
										type: t("edit.difference.types.dispo"),
									})}
								</Button>
							}
							onClick={() => props.data.resetOrderDispo("disposition")}
						/>
					)}
				</Segment>
			</Tab.Pane>
		);
	}

	let noDisposition = (
		<Fragment>
			<Table.Body>
				<Table.Row>
					<Table.Cell>
						<Segment placeholder>
							<Header icon>
								<Icon name="truck" />
								{t("edit.nodata.dispo.message")}
							</Header>
						</Segment>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Fragment>
	);

	let dispositon = (
		<Fragment>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>
						{t("edit.fields.dispoDeliverer.label")}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t("edit.fields.dispoType.label")}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t("edit.fields.dispoAmount.label")}
					</Table.HeaderCell>
					{!props.edit.state.data.editable ||
					props.data.state.formData.bpo.length ? null : (
						<Table.HeaderCell />
					)}
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{Object.entries(state.data).map(([key, x]) => (
					<Table.Row key={x.key}>
						<Table.Cell>
							<BaseDataSelect
								value={x.supplier}
								sortUp={props.edit.state.suppliers}
								onChange={(e, { value }) => {
									props.data.changeOrderDispo(
										"disposition",
										"supplier",
										value,
										x.reference
									);
									props.edit.saveHistory();
								}}
								disabled={
									!props.edit.state.data.editable ||
									!!props.data.state.formData.bpo.length
								}
								type="suppliers"
							/>
						</Table.Cell>
						<Table.Cell>
							<BaseDataSelect
								value={x.type}
								omit={!!props.data.state.formData.bpo.length ? [] : ["?"]}
								onChange={(e, { value }) => {
									props.data.changeOrderDispo(
										"disposition",
										"type",
										value,
										x.reference
									);
									props.edit.saveHistory();
								}}
								type="truckTypes"
								disabled={
									!props.edit.state.data.editable ||
									!!props.data.state.formData.bpo.length
								}
							/>
						</Table.Cell>
						<Table.Cell>
							<BlurChangeInput
								placeholder={t("edit.fields.dispoAmount.placeholder")}
								value={x.amount}
								type="number"
								fluid
								onBlur={(e) => {
									props.data.changeOrderDispo(
										"disposition",
										"amount",
										isNaN(parseInt(x.amount)) || x.amount < 0
											? 0
											: parseInt(x.amount),
										x.reference
									);
									props.edit.saveHistory();
								}}
								onChange={(e, { value }) =>
									props.data.changeOrderDispo(
										"disposition",
										"amount",
										value,
										x.reference,
										true
									)
								}
								disabled={
									!props.edit.state.data.editable ||
									!!props.data.state.formData.bpo.length
								}
							/>
						</Table.Cell>
						{!props.edit.state.data.editable ||
						props.data.state.formData.bpo.length ? null : (
							<Table.Cell>
								<ConfirmButton
									ok="yes"
									cancel="no"
									trigger={
										<Button
											floated="right"
											icon="trash alternate"
											size="small"
										/>
									}
									onClick={(e) => {
										props.data.changeOrderDispo(
											"disposition",
											"deleted",
											true,
											x.reference
										);
										props.edit.saveHistory();
									}}
								/>
							</Table.Cell>
						)}
					</Table.Row>
				))}
			</Table.Body>
		</Fragment>
	);

	return (
		<Tab.Pane>
			{truckTonnage ? (
				<Message
					size="small"
					content={t("edit.truckTonnage") + ": " + truckTonnage + "t"}
				/>
			) : null}
			<Table singleLine>
				{Object.entries(state.data).length === 0 &&
				state.data.constructor === Object
					? noDisposition
					: dispositon}
				{!props.edit.state.data.editable ||
				!!props.data.state.formData.bpo.length ? null : (
					<Table.Footer fullWidth>
						<Table.Row>
							<Table.HeaderCell colSpan="4">
								<Button
									floated="right"
									icon
									labelPosition="left"
									primary
									size="small"
									onClick={(_) => props.data.addOrderDispo("disposition")}
								>
									<Icon name="user" /> {t("edit.buttons.newDispo")}
								</Button>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				)}
			</Table>
		</Tab.Pane>
	);
};

export default EditDispo;
