import React from "react";
import ZoomControl from "./controls/zoomControl";
import ClassDropdown from "./controls/classDropdown";
import CollisionWarner from "./collisionWarner";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Button, Loader, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DuravermeerExport from "../exports/duravermeerExport";
import SchuetzExport from "../exports/schuetzExport";
import BackesExport from "../exports/backesExport";
import DetailExporter from "../exports/detailExporter";
import Bauzeitenplan from "../exports/bauzeitenplan";
import StandardExport from "../exports/standardExport";
import ProcessTypeChooser from "./controls/processTypeChooser";
import AGFModal from "../addons/AGFModal";
import IntervalChooser from "./projecteditview/intervalChooser";

const CalendarButtons = (props) => {
	return (
		<div className="buttonLine" id="calendar_buttons">
			<div className="buttonBlock">
				<ZoomControl />
				{props.app.ui.isLoading && props.app.ui.backgroundLoading ? (
					<Loader active inline />
				) : null}
			</div>
			{props.edit ? (
				<React.Fragment>
					{window.bpo ? (
						<div className="buttonBlock">
							<Button.Group>
								{props.edit.state.data.editable ? (
									<Button
										onClick={(_) => {
											console.log(
												props.app.ui.saveNecessary(),
												props.edit.state.neverChanged,
												props.edit.state
											);
											!props.app.ui.saveNecessary() &&
											props.edit.state.neverChanged
												? props.edit.openBAP()
												: props.app.ui.openMessage("edit.saveFirst");
										}}
									>
										{props.t("edit.bpo.openBAP")}
									</Button>
								) : null}
								<Button
									onClick={(_) => {
										console.log(
											props.app.ui.saveNecessary(),
											props.edit.state.neverChanged,
											props.edit.state
										);
										!props.app.ui.saveNecessary() &&
										props.edit.state.neverChanged
											? props.edit.openMixtureLetter()
											: props.app.ui.openMessage("edit.saveFirst");
									}}
								>
									{props.t("edit.bpo.mixtureLetter")}
								</Button>
							</Button.Group>
						</div>
					) : null}

					{props.edit.state.data.editable ? (
						<div className="buttonBlock">
							<Button.Group>
								<Button
									icon="undo alternate"
									disabled={props.edit.state.history.length < 2}
									onClick={() => props.edit.undo()}
								/>
								<Button
									icon="redo alternate"
									disabled={props.edit.state.forwardHistory.length < 1}
									onClick={() => props.edit.redo()}
								/>
							</Button.Group>
							<Popup content={props.t("calButtons.lockMoving")} trigger={<Button
									icon={props.edit.state.data.fixed ? "lock" : "lock open"}
									onClick={() => props.edit.setFixed(!props.edit.state.data.fixed)}
							/>} />
							{props.app.ui.modules.has("AGF") ? (
								<AGFModal size={null} projectId={props.edit.state.pid} />
							) : null}
							<Bauzeitenplan editState={props.edit.state} />
							<IntervalChooser
								edit={props.edit}
								templates={props.app.basedata.processTemplates}
							/>
							<Button
								icon="clone"
								active={props.edit.state.multiMode}
								labelPosition="left"
								content={props.t("calButtons.multiMode")}
								onClick={(_) =>
									props.edit.setMultiMode(!props.edit.state.multiMode)
								}
							/>
							<Button
								icon="copy"
								active={!!props.edit.state.copyOrigins.size}
								labelPosition="left"
								content={props.t("calButtons.copy")}
								onClick={(_) => props.edit.copyMode()}
							/>
							<Button
								icon="trash alternate"
								active={props.edit.state.deleteMode}
								labelPosition="left"
								content={props.t("calButtons.deleteMode")}
								onClick={(_) =>
									props.edit.setDeleteMode(!props.edit.state.deleteMode)
								}
							/>
							<ProcessTypeChooser
								onSelect={(template) => props.edit.addProcess(template)}
								trigger={
									<Button
										labelPosition="left"
										content={props.t("calButtons.addProcess")}
										icon="add"
									/>
								}
							/>
						</div>
					) : null}
				</React.Fragment>
			) : (
				<React.Fragment>
					<div className="buttonBlock">
						{props.app.ui.rights.has("VIEW_RESOURCES") ||
						props.app.ui.rights.has("VIEW_BOARD") ? (
							<Button.Group>
								<Button
									active={props.app.ui.mode === "CALENDAR"}
									onClick={(_) => props.app.ui.setMode("CALENDAR")}
								>
									{props.t("calButtons.mode.calendar")}
								</Button>
								{props.app.ui.rights.has("VIEW_RESOURCES") ? (
									<Button
										active={props.app.ui.mode === "RESOURCES"}
										onClick={(_) => props.app.ui.setMode("RESOURCES")}
									>
										{props.t("calButtons.mode.disposition")}
									</Button>
								) : null}
								{props.app.ui.rights.has("VIEW_BOARD") ? (
									<Button
										active={props.app.ui.mode === "BOARD"}
										onClick={(_) => props.app.ui.setMode("BOARD")}
									>
										{props.t("calButtons.mode.board")}
									</Button>
								) : null}
							</Button.Group>
						) : null}
					</div>

					<div className="buttonBlock">
						{window.bpo && props.app.ui.modules.has("EXPORT_DURAVERMEER") ? (
							<DuravermeerExport />
						) : null}
						{window.bpo && props.app.ui.modules.has("EXPORT_SCHUETZ") ? (
							<SchuetzExport />
						) : null}
						{window.bpo && props.app.ui.modules.has("EXPORT_STANDARD") ? (
							<StandardExport />
						) : null}
						{window.bpo && props.app.ui.modules.has("EXPORT_BACKES") ? (
							<BackesExport />
						) : null}
						{props.app.ui.mode === "BOARD" ? <DetailExporter /> : null}
						{props.app.ui.modules.has("DAY_VIEW") &&
						(props.app.ui.rights.has("VIEW_RESOURCES") ||
							props.app.ui.rights.has("VIEW_BOARD")) ? (
							<Button
								onClick={() => props.app.ui.setDayView(true)}
								icon="calendar alternate"
							/>
						) : null}
						{props.app.ui.mode === "RESOURCES" ? (
							<React.Fragment>
								{props.app.ui.rights.has("COLLISION_CHECKER") ? (
									<CollisionWarner
										lastChange={props.app.resources.lastCollisionRelevantChange}
									/>
								) : null}
								{props.app.ui.rights.has("EDIT_GROUPS") ? (
									<Button as={Link} to="/groups" icon="group" />
								) : null}
								{props.app.ui.modules.has("RESOURCE_MAP") &&
								props.app.ui.rights.has("RESOURCE_MAP") ? (
									<Button as={Link} to="/map" icon="map marker alternate" />
								) : null}
							</React.Fragment>
						) : null}
						{props.app.ui.mode !== "CALENDAR" ? (
							<ClassDropdown
								onChange={props.app.ui.setClassDropdown}
								value={Array.from(props.app.ui.currentClass)}
								options={props.app.resources.getClassDropdownOptions(
									props.t,
									props.app.ui.mode !== "BOARD"
								)}
							/>
						) : props.app.ui.rights.has("CREATE_PROJECTS") ? (
							<Button
								as={Link}
								to="/project"
								content={props.t("calButtons.addProject")}
								icon={"plus"}
								labelPosition="left"
							/>
						) : null}
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default inject("app")(withTranslation()(observer(CalendarButtons)));
