import React from "react";
import { inject, observer } from "mobx-react";
import { isValidReference } from "mobx-state-tree";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import arrayMove from "array-move";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import TimeButtons from "../components/report/timebuttons";
import axios from "axios";
import {
  Button,
  Loader,
  Message,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import QuestionaryEditModal from "../components/questions/QuestionaryEditModal";
import QuestionaryModal from "../components/questions/QuestionaryModal";
import Codes from "../components/questions/Codes";
import ConfirmButton from "../components/calendar/controls/confirmButton";
import ReportModal from "../components/questions/ReportModal";
import {
  SortableElement,
  sortableHandle,
  SortableContainer,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
  <Icon
    name="bars"
    size="large"
    style={{ cursor: "row-resize", marginRight: 10 }}
  />
));

const Questionary = SortableElement(({ x, last }) => (
  <Segment
    className="questionarySegment"
    attached={last ? "bottom" : true}
    key={x.id}
  >
    <div className="namecell">
      <DragHandle />
      {x.name}
    </div>
    <div className="buttoncell">
      <QuestionaryModal
        questionaryId={x.id}
        classConstraints={
          new Set(
            Array.from(x._resClasses.values())
              .filter((y) => isValidReference(() => y) && !y.deleted)
              .map((y) => y.id)
          )
        }
        trigger={<Button icon="pencil" />}
      />
      <QuestionaryEditModal
        questionaryId={x.id}
        trigger={<Button icon="settings" />}
      />
      <ConfirmButton
        onClick={(e) => x.delete()}
        question={["questions.delete", { name: x.name }]}
        ok="yes"
        cancel="no"
        trigger={<Button icon="trash alternate" />}
      />
    </div>
  </Segment>
));

const Questionaries = SortableContainer(({ items }) => (
  <div>
    {items.map((x, i, items) => (
      <Questionary key={x.id} x={x} last={i === items.length - 1} index={i} />
    ))}
  </div>
));

class QuestionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      start: moment().startOf("month"),
      end: moment().startOf("day"),
      focusedInput: null,
      loading: true,
      error: false,
      results: [],
    };
  }

  componentDidMount() {
    /*this.props.appCtx.setEditButtons(
      <Link className="topbutton" to="/">
        <img alt="Back" src={WahlImg} />
      </Link>,
      null
    ); */

    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.start &&
      this.state.end &&
      (!prevState.start ||
        !prevState.end ||
        this.state.start !== prevState.start ||
        this.state.end !== prevState.end)
    ) {
      this.load();
    }
  }

  async load() {
    const start = this.state.start.valueOf();
    const end = this.state.end.endOf("day").valueOf();
    const jwttoken = localStorage.getItem("binfra_token");
    this.setState({ error: false, loading: true });
    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          "/loadReport/" +
          start +
          "/" +
          end +
          "?v="+process.env.REACT_APP_SERVER_VERSION+"&" +
          Math.random(),
        {
          headers: {
            Authorization: "Bearer " + jwttoken,
          },
        }
      );
      this.setState({
        error: false,
        loading: false,
        results: res.data
          .filter((x) => this.props.app.projects.projects.has(x.project))
          .map((x) => {
            return {
              ...x,
              projectName: this.props.app.projects.projects.get(x.project)
                .fullName,
              dateFormatted: moment(x.date).format("DD.MM.YYYY"),
            };
          })
          .sort((a, b) =>
            a.date < b.date
              ? -1
              : a.date > b.date
              ? 1
              : a.projectName.localeCompare(b.projectName)
          ),
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.app.ui.setLoginNecessary("callback", this.load.bind(this));
      }
      this.setState({ error: true, loading: false });
      console.log(error);
    }
  }

  componentWillUnmount() {
    //this.props.appCtx.setEditButtons(false);
  }

  render() {
    const questionaries = this.state.edit
      ? Array.from(this.props.app.questions.questionaries.values())
          .filter((x) => !x.deleted)
          .sort((a, b) =>
            a.ordering < b.ordering
              ? -1
              : b.ordering < a.ordering
              ? 1
              : a.name.localeCompare(b.name)
          )
      : [];

    return (
      <div id="questionPage" className="questionPage groupPage">
        <div id="map_buttons" className="buttonLine">
          <div className="buttonBlock">
            {this.state.edit ? (
              <Button
                onClick={() => this.setState({ edit: false })}
                labelPosition="left"
                content={this.props.t("back")}
                icon="arrow left"
              />
            ) : (
              <DateRangePicker
                startDate={this.state.start ? this.state.start : null} // momentPropTypes.momentObj or null,
                startDateId="dpicker_start" // PropTypes.string.isRequired,
                calendarInfoPosition="bottom"
                numberOfMonths={3}
                endDate={this.state.end ? this.state.end : null} // momentPropTypes.momentObj or null,
                endDateId="dpicker_end" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) =>
                  this.setState({
                    start: startDate,
                    end: endDate,
                  })
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                } // PropTypes.func.isRequired,
                renderCalendarInfo={(x) => (
                  <TimeButtons
                    onClick={(start, end) =>
                      this.setState({ start: moment(start), end: moment(end) })
                    }
                  />
                )}
                startDatePlaceholderText={this.props.t("begin")}
                endDatePlaceholderText={this.props.t("end")}
                hideKeyboardShortcutsPanel
                isOutsideRange={() => false}
                minimumNights={0}
                withPortal={true}
              />
            )}
          </div>
          <div className="buttonBlock">
            {this.state.edit ? (
              <QuestionaryEditModal
                questionaryId={null}
                trigger={
                  <Button
                    labelPosition="left"
                    content={this.props.t("questions.addQuestionary")}
                    icon="add"
                  />
                }
              />
            ) : (
              <React.Fragment>
                <Codes />
                {this.props.app.ui.rights.has("MODIFY_QUESTIONS") ? (
                  <Button
                    onClick={() => this.setState({ edit: true })}
                    labelPosition="left"
                    content={this.props.t("questions.editQuestionaries")}
                    icon="pencil alternate"
                  />
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="groupBody">
          {this.state.edit ? (
            <div className="mockTable">
              <Segment attached="top" className="thead">
                {this.props.t("questions.questionaries.header")}
              </Segment>
              {questionaries.length ? (
                <Questionaries
                  useDragHandle
                  items={questionaries}
                  helperClass="sortableHelper"
                  onSortEnd={({ oldIndex, newIndex }) => {
                    const xq = arrayMove(questionaries, oldIndex, newIndex);
                    for (let i = 0; i < xq.length; i++) {
                      xq[i].setOrdering(i);
                    }
                    return;
                  }}
                />
              ) : (
                <Segment attached="bottom">
                  <Message
                    fluid
                    header={this.props.t("questions.noQuestions.header")}
                    content={this.props.t("questions.noQuestions.text", {
                      button: this.props.t("questions.addQuestionary"),
                    })}
                  />
                </Segment>
              )}
            </div>
          ) : (
            <div className="mockTable">
              {this.state.error ? (
                <Header
                  style={{ margin: 20, textAlign: "center", display: "block" }}
                  className="connectionError"
                  icon
                >
                  <Icon name="wifi" />
                  {this.props.t("loader.networkError")}
                  <Header.Subheader>
                    <Button onClick={(x) => this.load()}>
                      {this.props.t("loader.tryAgain")}
                    </Button>
                  </Header.Subheader>
                </Header>
              ) : this.state.loading ? (
                <Loader
                  style={{ margin: 20 }}
                  active
                  inline="centered"
                  size="large"
                >
                  {this.props.t("report.loading")}
                </Loader>
              ) : (
                <React.Fragment>
                  <Segment attached="top" className="thead">
                    {this.props.t("questions.reports.header")}
                  </Segment>
                  {this.state.results.length > 0 ? (
                    this.state.results.map((x, i, o) => (
                      <Segment
                        className="questionarySegment"
                        attached={i + 1 === o.length ? "bottom" : true}
                        key={x.project + x.date}
                      >
                        <div className="namecell">
                          {x.dateFormatted}
                          {" - "}
                          {x.projectName}
                        </div>
                        <div className="buttoncell">
                          <ReportModal data={x} project={x.project} date={x.date} />
                        </div>
                      </Segment>
                    ))
                  ) : (
                    <Segment attached="bottom">
                      <Message
                        fluid
                        icon="calendar times outline"
                        header={this.props.t("nothingFoundMessage.header")}
                        content={this.props.t("nothingFoundMessage.message")}
                      />
                    </Segment>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default inject("app")(
  withTranslation()(withRouter(observer(QuestionPage)))
);
