import React from "react";
import { observer, inject } from "mobx-react";
import GroupLine from "./groupLine";
import ResourceLine from "./resourceLine";
import ResourceDetailLine from "./details/resourceDetailLine";

const ResourceLines = ({ app: { resources, ui }, width, edit }) => (
	<React.Fragment>
		{!(ui.currentClass.length === 1 && ui.currentClass[0] === "GROUPS")
			? Array.from(resources.getResourceLines())
					.sort((a, b) => {
						if (!a.resclass || !b.resclass) return 0;
						if (!a.resclass.human && b.resclass.human) return 1;
						if (a.resclass.human && !b.resclass.human) return -1;
						const cx = a.resclass.name.localeCompare(b.resclass.name);
						if (cx === 0) return a.name.localeCompare(b.name);
						return cx;
					})
					.map((p) =>
						ui.mode === "BOARD" ? (
							<ResourceDetailLine key={p.id} xwidth={width} resource={p} />
						) : (
							<ResourceLine key={p.id} xwidth={width} resource={p} />
						)
					)
			: null}
		{ui.currentClass.includes("GROUPS") || ui.currentClass.includes("ALL")
			? resources
					.getGroupLines()
					.map((p) => <GroupLine key={p.id} xwidth={width} group={p} />)
			: null}
	</React.Fragment>
);

export default inject("app")(observer(ResourceLines));
