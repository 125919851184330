import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { strToDate } from "../../../helpers/calendar";

class ProjectResourceLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static getX(props, date) {
		return (
			((date.getTime() - props.app.ui.view.start.getTime()) /
				props.app.ui.calDuration) *
			props.xwidth
		);
	}

	static getDate(props, x) {
		return (props.app.ui.calDuration * x) / props.xwidth;
	}

	static getWidth(props, x) {
		return (x * props.xwidth) / props.app.ui.calDuration;
	}

	render() {
		const isGroupLine = this.props.group === true;
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;

		const data = this.props.data;
		const boxes = [];

		const lineHeight = isGroupLine ? 20 : 30;
		const padding = 0;
		const boxHeight = lineHeight - 2 * padding;

		for (let [personalId, dateData] of Object.entries(data.days)) {
			let start = ProjectResourceLine.getX(
				this.props,
				strToDate(personalId)
			);
			let end = ProjectResourceLine.getX(
				this.props,
				strToDate(personalId, 1)
			);
			let processes = dateData.map(x => x.process.id);
			let isBeingMoved = this.props.pstate.dayMap.has(personalId);

			const isNight = dateData.reduce((a,c) => c.isNight||a,false);

			let pos = 0;
			let offset = 0;

			if (this.props.pstate.pid === "") {
			} else if (
				isBeingMoved &&
				(this.props.pstate.sub === "main" ||
					processes.includes(this.props.pstate.sub))
			) {
				offset = this.props.pstate.dayMap.get(personalId);
			}

			const hovered =
				this.props.app.ui.mouseOver.day === personalId &&
				this.props.app.ui.mouseOver.resource === data.resource.id;

			boxes.push(
				<div
					key={personalId}
					className={
						(offset !== 0 || pos !== 0
							? "standard_box moved"
							: "standard_box") + (hovered ? " hovered" : "") + (isNight ? " night" : "")
					}
					onMouseEnter={e =>
						this.props.app.ui.setMouseOver({
							projects: [],
							processes: processes,
							resource: data.resource.id,
							day: personalId
						})
					}
					onMouseLeave={e => this.props.app.ui.resetMouseOver()}
					style={{
						cursor: "pointer",
						position: "absolute",
						left: start + 1 + offset,
						width: end - start - 1,
						height: boxHeight - 1,
						top: padding
					}}
				/>
			);
		}

		return (
			<tr key={"tr" + data.resource.id} className={"project_resource"+(isGroupLine ? " groupline": "")}>
				<td
					className="project_resource_head sticky"
					style={{
						cursor: "pointer",
						left: 0,
						zIndex: 3,
						verticalAlign: "top"
					}}
				>
					<div
						className="resource_name"
						style={{
							whiteSpace: "nowrap",
							height: lineHeight
						}}
						onClick={
							("group" in this.props) && this.props.group !== true ? this.props.group : null
						}
					>
						{isGroupLine ? null : (<div
							className={
								"imageInner" +
								(data.isRentalResource ? " rentalResource" : "") +
								(data.isGroup ? " iiGroup picon picon-group" : "")
							}
							style={
								data.isRentalResource || data.isGroup
									? null
									: {
											backgroundImage:
												"url(" +
												data.resource.imageUrl +
												")"
									  }
							}
						>
							{data.isRentalResource
								? this.props
										.t("resourceChooser.rent")
										.substr(0, 1)
								: ""}
						</div>)}
						<div className="nameInner">{data.resource.name}</div>
					</div>
				</td>
				<td>
					<div
						className="project_body"
						style={{
							zIndex: 2,
							width: width,
							height: lineHeight,
							position: "relative"
						}}
					>
						{boxes}
					</div>
				</td>
			</tr>
		);
	}
}

export default inject("app")(withTranslation()(observer(ProjectResourceLine)));
