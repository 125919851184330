import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import {
	Button,
	Menu,
	Modal,
	Icon
} from "semantic-ui-react";
import moment from "moment";

class DetailExporter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			data: {}
		};
	}

	open() {

				const start = moment(this.props.app.ui.calStart).format("YYYY-MM-DD");
		const end = moment(this.props.app.ui.calEnd).add(1,"day").format("YYYY-MM-DD");

		let data = {
			resources: [],
			start: start,
			end: end
		};


		let rescount = -1;
		let rowcount = 0;
		for (let row of document.querySelectorAll(".xls_row")){
			const resnameE = row.querySelector(".xls_resname");
			if (resnameE){
				rowcount = 0;
				rescount++;
				data.resources.push({
					name: resnameE.textContent,
					rows: [[]]
				});
			} else {
				data.resources[rescount].rows.push([]);
				rowcount++;
			}
			for (let box of row.querySelectorAll(".xls_box")){
				//console.log(box.dataset.start, start ,end);
				if (box.dataset.start < start || box.dataset.start > end)
					continue;
				const titleBox = box.querySelector(".xls_title");
				const startBox = box.querySelector(".xls_start");
				const endBox = box.querySelector(".xls_end");
				const contentBox = box.querySelector(".xls_content");
				const size = box.dataset.size ? (parseInt(box.dataset.size)-1) : 0;
				let boxdata = {
					date: box.dataset.start,
					title: titleBox ? titleBox.textContent : "",
					start: startBox ? startBox.textContent : "",
					end: endBox ? endBox.textContent : "",
					content: contentBox ? contentBox.textContent.replace(/\n\n/g,"\n").trim() : ""
				};
				data.resources[rescount].rows[rowcount-size].push(boxdata);
			}
		}

		this.setState({ open: true, data });

	}

	close() {
		this.setState({ open: false });
	}

	render() {
		//console.log(this.state.data);
		return (
			<Modal
				centered={false}
				className="detailexportModal"
				trigger={
					<Button icon="print" onClick={(e) => this.open()} />
				}
				open={this.state.open}
				onClose={(e) => this.close()}
			>
				<React.Fragment>
					<Menu>
						<Menu.Item header>{this.props.t("exports.heading")}</Menu.Item>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={(e) => this.close()}>
								<Icon name="close" />
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					<Modal.Content>
						
						<form
							className="hiddenForm"
							method="post"
							action={""+process.env.REACT_APP_BPO_URL+"/ressourcen/InfraDetailExport.php"}
							target="_blank"
						>
							
							<input type="hidden" name="duration" value={JSON.stringify(this.state.data)} />
							<Button type="submit" positive fluid>
								{this.props.t("exports.get")}
							</Button>
						</form>
					</Modal.Content>
				</React.Fragment>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(DetailExporter)));
